/**
 *
 * Nav
 *
 */
import * as React from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { media, sizes } from '../../../styles/media';
import { Logo } from './Logo';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import { ChangeEvent } from 'react';
import { useAppSlice } from '../../slice';
import { FaRegBell, FaCog, FaSignOutAlt, FaBars } from 'react-icons/fa';
import { BsChevronDown, BsSearch } from 'react-icons/bs';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectUser,
  selectError,
  selectLoading,
  selectToken,
  selectSuccess,
  selectNotifications,
  selectApp
} from '../../slice/selectors';
import { AvatarDropdown } from './AvatarDropdown'
import { NotificationDropdown } from './NotificationDropdown'


interface Props {
  toggleSideBar?: () => void
}

// export const NotifcationLink: React.FC<{notification:any}> = ({children, notification}) => {
//   const history = useNavigate();
//   let actor = ''
//   actor = notification.actor.username
//   let verb = ''
//   verb = notification.type.verb
//
//   return (<ListItemContainer onClick={()=>{if(notification.url !== null){history(notification.url)}}}>
//       <Flex alignItems={'center'} pl={3} pr={3} pb={2} pt={2}>
//         <Box w={'3%'}>
//         {notification && notification.is_read === false
//         ? <UnreadIndicator/>
//         : null
//         }
//         </Box>
//
//
//         <Text ml={2} mr={1} color="white" size="12px" >
//
//         {notification && notification.type.id === 2
//         ? (` ${notification.actor.first_name}, ${verb}` ||  <Skeleton height="20px" />)
//         : (
//           <>
//           <Link variant="inverted" href={'/creators/'+notification.actor.slug}>@{actor}</Link>
//           { ` ${verb} ` ||  <Skeleton height="20px" />}
//           </>
//         )
//         }
//
//
//         </Text>
//       </Flex>
//       </ListItemContainer>
//   );
// }

export function Nav(props: Props) {
  const { actions } = useAppSlice();

  const [user, set_user] = React.useState({
    username: '',
    first_name: '',
    last_name: '',
    profile_image: '',
    notifications: [],
    role_id: 1,
  });


  const history = useNavigate();
  const dispatch = useDispatch();

  const appUser = useSelector(selectUser);
  const appToken = useSelector(selectToken);
  const notifications = useSelector(selectNotifications);
  const loading = useSelector(selectLoading);
  const errors = useSelector(selectError);
  const appState = useSelector(selectApp);



  const [isLoggedIn, set_isLoggedIn] = React.useState(false);

  React.useEffect(() => {
    if (appUser !== null && appUser !== undefined) {
      set_user(appUser);
    }
  }, [appUser]);

  React.useEffect(() => {
    const userStorage = localStorage.getItem('user');
    if (userStorage !== "undefined" && userStorage !== null ) {
      var parsedUser = JSON.parse(userStorage);
      set_user(parsedUser);
      setTimeout(() => dispatch(actions.loadNotifications()), 100);
    }
  }, []);

  React.useEffect(() => {
    if (appUser !== null && appUser !== undefined && appToken !== null && appToken !== undefined) {
      set_isLoggedIn(true);
    }
  }, [appUser, appToken]);

  function handleLogout(e: any) {
    setTimeout(() => dispatch(actions.logout()), 100);
  }

  function handleSideBarToggle(e: any) {
    if (props.toggleSideBar) {
      props.toggleSideBar()
    }
  }

  function handleClearNotif(e: any) {
    setTimeout(() => dispatch(actions.clearNotifications()), 100);
  }




  return (
    <div className="header-wrapper">
      <div className="header-right">
        {appState.is_authenticated === true
        ? (
          <div onClick={handleSideBarToggle} style={{cursor:"pointer"}}>
          <FaBars size="2rem"/>
          </div>
        )
        : null
        }
        <Logo />
      </div>

      {appState.is_authenticated === true
      ? (
        <div className="header-right">
          {notifications &&
            <NotificationDropdown handleClearNotif={handleClearNotif} notifications={notifications} loading={loading}/>
          }

          <AvatarDropdown user={user} handleLogout={handleLogout}/>
        </div>
      )
      : null
      }
    </div>
  );
}
