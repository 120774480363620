/* --- STATE --- */
export interface AppState {
  loading: boolean;
  success: boolean;
  error?: AppErrorType | null;
  payload?: any | null;
  user?: any | null;
  token?: string | null;
  onboard_success:boolean;
  is_authenticated: boolean;
  token_reset_sucess:boolean;
  changepw_sucess:boolean;
  notifications: any[] | null;
  appAlert: string;
  appAlertOpen: boolean;
  appAlertSeverity: AlertColor;
}

export type AlertColor = 'success' | 'error' | 'warning' | 'info';

export enum AppErrorType {
  RESPONSE_ERROR = 1,
  SIGNUP_ERROR = 2,
  LOGIN_ERROR = 3,
  FOROGTPW_ERROR = 4,
  NOTIFICATIONS_ERROR = 5,
  USER_ERROR = 6,
}
