import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Nav } from '../../components/Nav';
import { Logo } from '../../components/Nav/Logo';
import { Footer } from '../../components/Footer';
import { LayoutNoSidebar } from '../../components/Layout';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link, useLocation} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  selectLoading,
  selectPayload,
  selectError,
  selectUser,
  selectToken,
  selectSuccess,
  selectOnboardSuccess
} from '../../slice/selectors';
import {Grid, Box, Avatar, TextField, FormControl, FormLabel,  Typography, Button, CircularProgress} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useAppSlice } from '../../slice';
import { renderAPIMsg } from '../../../utils/request';



export function Invite() {
  const history = useNavigate();
  const { actions } = useAppSlice();

  const dispatch = useDispatch();

  const authUser = useSelector(selectUser);
  const loading = useSelector(selectLoading);
  const errors = useSelector(selectError);
  const success = useSelector(selectSuccess);
  const payload = useSelector(selectPayload);
  const onboard_success = useSelector(selectOnboardSuccess);

  const { t, i18n } = useTranslation();

  const [first_name, set_first_name] = React.useState('')
  const [last_name, set_last_name] = React.useState('')
  const [password, set_password] = React.useState('')
  const [confirm_password, set_confirm_password] = React.useState('')

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();

  const token = query.get('token')


  React.useEffect(() => {
    dispatch(actions.verifyInviteToken({token:token}))
  }, [token]);

  React.useEffect(() => {
    if (onboard_success !== undefined && onboard_success !==null && onboard_success === true) {
      history(`/`)
    }
  }, [onboard_success]);


  function completeSetup(e:any) {
    dispatch(actions.completeInviteOnboard({first_name:first_name, last_name:last_name, password:password, confirm_password:confirm_password}))
  }


  return (
    <>
      <Helmet>
        <title>Login</title>
        <meta name="description" content="Security compliance made easy" />
      </Helmet>
      <LayoutNoSidebar>
      <Grid container spacing={2} justifyContent={'center'}>
        <Grid item xs={12}>
          <Logo/>
        </Grid>
        <Grid item xs={12} sm={8} md={9} lg={6}>
          <div className="split-card-container">
            <div className="col-flex">
              <div className="col-left">
                <div className="card-header">
                  <h2>Welcome To Solvr, complete account setup...</h2>
                  {errors !== undefined && errors !== null
                  ? <p className="red">{renderAPIMsg(errors)}</p>
                  :null}
                </div>
                <div className="card-body">
                  {loading
                    ? <CircularProgress/>
                    : (
                      <>
                      <FormControl sx={{width:"100%"}}>
                        <FormLabel>
                          First Name
                        </FormLabel>
                        <TextField id="standard-basic" label="" variant="standard" name="first_name" value={first_name} onChange={(e)=>set_first_name(e.target.value)}/>
                      </FormControl>
                      <FormControl sx={{mt:3,width:"100%"}}>
                        <FormLabel>
                          Last Name
                        </FormLabel>
                        <TextField id="standard-basic" label="" variant="standard" name="last_name" value={last_name} onChange={(e)=>set_last_name(e.target.value)}/>
                      </FormControl>
                      <FormControl sx={{mt:3, width:"100%"}}>
                        <FormLabel>
                          Password
                        </FormLabel>
                        <TextField type="password" id="standard-basic" label="" variant="standard" name="password" value={password} onChange={(e)=>set_password(e.target.value)}/>
                      </FormControl>
                      <FormControl sx={{mt:3, width:"100%"}}>
                        <FormLabel>
                          Confirm Password
                        </FormLabel>
                        <TextField type="password" id="standard-basic" label="" variant="standard" name="confirm_password" value={confirm_password} onChange={(e)=>set_confirm_password(e.target.value)}/>
                      </FormControl>

                      {payload && payload.qr &&
                        (<>
                          <p>Scan the QR Below for Quick Google Authenticator Access</p>
                          <img src={payload.qr}/>
                        </>)
                      }
                      <Box display="flex" flexDirection="row" justifyContent="center" width="100%" flexWrap="wrap" textAlign="center" mt={3}>
                        <Button variant="contained" sx={{borderRadius:25}} onClick={completeSetup}>Complete Setup</Button>
                      </Box>
                      </>
                    )
                  }
                </div>
              </div>
              <div className="col-right">
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                  <p><b>Not Registered Yet?</b></p>
                  <p style={{margin:0, paddingBottom:20}}>Join the thousands of different companies looking to streamline their security and DI policies.</p>
                </Box>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      </LayoutNoSidebar>
    </>
  );
}
