import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Nav } from '../../components/Nav';
import { Logo } from '../../components/Nav/Logo';
import { Footer } from '../../components/Footer';
import { LayoutNoSidebar } from '../../components/Layout';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  selectLoading,
  selectPayload,
  selectError,
  selectUser,
  selectToken,
  selectSuccess,
  selectTokenResetSuccess,
  selectChangePWSuccess
} from '../../slice/selectors';
import {Grid, Box, Avatar, TextField, FormControl, FormLabel,  Typography, Button, CircularProgress} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useAppSlice } from '../../slice';
import { renderAPIMsg } from '../../../utils/request';



export function ResetPW() {
  const history = useNavigate();
  const { actions } = useAppSlice();

  const dispatch = useDispatch();

  const authUser = useSelector(selectUser);
  const loading = useSelector(selectLoading);
  const errors = useSelector(selectError);
  const success = useSelector(selectSuccess);
  const payload = useSelector(selectPayload);
  const resetSuccess = useSelector(selectTokenResetSuccess);
  const changePWSuccess = useSelector(selectChangePWSuccess);




  const { t, i18n } = useTranslation();

  const [newPassword, setNewPassword] = React.useState('')
  const [confirmPassword, setConfirmPassword] = React.useState('')

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const params = useParams();

  const token = params.token



  React.useEffect(() => {
    if (errors) {
      dispatch(actions.set_error({message:"Token verification failed. Please try again with a valid token."}))
    }
  }, [errors]);


  React.useEffect(() => {
    if (token) {
      try {
        dispatch(actions.verifyResetToken({token:token}))
      } catch (error) {
        console.log(error)
      }
    }
  }, [token]);

  function handleChangePW(e:any) {

    if (newPassword !== confirmPassword) {
      dispatch(actions.set_error({message:"Passwords do not match."}))
    }
    try {
      dispatch(actions.changePassword({token:token, password:newPassword}))
    } catch (error) {
      console.log(error)
    }
  }



  return (
    <>
      <Helmet>
        <title>Reset Password</title>
        <meta name="description" content="Security compliance made easy" />
      </Helmet>
      <LayoutNoSidebar>
      <Grid container spacing={2} justifyContent={'center'}>
        <Grid item xs={12}>
          <h1 className="white" style={{textAlign:"center"}}>Unleash the power of D & I</h1>
        </Grid>
        <Grid item xs={12} sm={8} md={9} lg={6}>
          <div className="split-card-container">
            <div className="col-flex">
              <div className="col-left">
                <div className="card-header">
                  <h2>Reset Password</h2>
                  {errors !== undefined && errors !== null
                  ? <p className="red">{renderAPIMsg(errors)}</p>
                  :null}
                </div>

                {loading &&
                  (
                    <>
                    <p style={{margin:0, paddingBottom:20}}>Checking reset token...</p>
                    <CircularProgress />
                    </>
                  )
                }

                {(resetSuccess && changePWSuccess === true)
                  ? (<>
                    <p style={{margin:0, paddingBottom:20}}>Password Change Successful...</p>
                    <Link to={"/login"}>Login</Link>
                    </>
                  ):
                  resetSuccess ?
                  (
                    <div className="card-body">
                      <form style={{width:"100%"}}>
                      <FormControl sx={{width:"100%"}}>
                        <FormLabel>
                          New Password
                        </FormLabel>
                        <TextField id="standard-basic" label="" variant="standard" name="email" onChange={(e)=> setNewPassword(e.target.value)} />
                      </FormControl>
                      <FormControl sx={{width:"100%"}}>
                        <FormLabel>
                          Confirm Password
                        </FormLabel>
                        <TextField id="standard-basic" label="" variant="standard" name="email" onChange={(e)=> setConfirmPassword(e.target.value)} />
                      </FormControl>
                      <Box display="flex" flexDirection="row" justifyContent="center" width="100%" flexWrap="wrap" textAlign="center" mt={1}>
                        <LoadingButton loading={loading} onClick={(e)=> handleChangePW(e)} variant="contained" sx={{borderRadius:25}}>Change Password</LoadingButton>
                      </Box>
                      </form>
                    </div>
                  )
                  :null
                }
              </div>
              <div className="col-right">
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                  <p style={{margin:0, paddingBottom:20}}>Join the thousands of different companies looking to streamline their security and DI policies.</p>
                </Box>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      </LayoutNoSidebar>
    </>
  );
}
