import { take, call, put, select, takeLatest, delay } from 'redux-saga/effects';
import { appActions as actions } from '.';
import { request } from 'utils/request';
import { selectPayload, selectError } from './selectors';
import { AppErrorType } from './types';
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
var os = require('os');
const host = window.location.protocol+"//"+process.env.REACT_APP_API_ROOT

export function* submitSignup(action) {
  yield delay(100);
  const requestURL = host + `/auth/register/`;
  try {
    // Call our request helper (see 'utils/request')
    var options = {
      body: JSON.stringify(action.payload),
      method: 'POST',
      encoding: 'utf8',
      headers: {
        'User-Agent': 'request',
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };
    const response = yield call(request, requestURL, options);
    if (response) {
      yield put(actions.signupSuccess(response));
    } else {
      yield put(actions.signupError(AppErrorType.RESPONSE_ERROR));
    }
  } catch (err) {
    if (err.response?.status === 404) {
      yield put(actions.signupError(AppErrorType.RESPONSE_ERROR));
    } else if (err.response?.status === 400) {
      let resp = null;
      resp = yield err.response.json();
      yield put(actions.signupError(resp));
    } else if (err.message === 'A user with that username already exists.') {
      yield put(actions.signupError(AppErrorType.USER_ERROR));
    } else {
      yield put(actions.signupError(AppErrorType.RESPONSE_ERROR));
    }
  }
}
export function* signupSuccessSaga(payload) {
  yield delay(100);
  yield put(
    actions.notify({
      msg: 'Signup successful! Please login using your new credentials...',
      severity: 'success',
    }),
  );
}

export function* signupErrorSaga(payload) {
  yield delay(100);
  const error = yield select(selectError);
  yield put(
    actions.notify({
      msg: 'Signup was unsucessful! Please try again or contact support for assistance.',
      severity: 'error',
    }),
  );
}

export function* submitLogin(action) {
  // yield takeLatest(actions.someAction.type, doSomething);
  yield delay(1000);
  const requestURL = host + `/auth/login/`;

  try {
    // Call our request helper (see 'utils/request')
    var options = {
      body: JSON.stringify(action.payload),
      method: 'POST',
      encoding: 'utf8',
      headers: {
        'User-Agent': 'request',
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };
    const response = yield call(request, requestURL, options);

    if (response) {
      yield put(actions.loginSuccess(response));
    } else {
      yield put(actions.loginError(AppErrorType.RESPONSE_ERROR));
    }
  } catch (err) {
    if (err.response?.status === 404) {
      yield put(actions.loginError(AppErrorType.RESPONSE_ERROR));
    } else if (err.message === 'Failed to fetch') {
      yield put(actions.loginError(AppErrorType.RESPONSE_ERROR));
    } else if (err.response?.status === 400) {
      let resp = null;
      resp = yield err.response.json();
      yield put(actions.loginError(resp));
    } else {
      let resp = null;
      resp = yield err.response.json();
      yield put(actions.loginError(resp));
    }
  }
}

export function* loginSuccessSaga(payload) {
  yield delay(100);
  const payloadToken = yield select(selectPayload);
  yield localStorage.setItem('token', payloadToken.token);
  yield localStorage.setItem('user', JSON.stringify(payloadToken.user));
  let decoded: any = jwt_decode(payloadToken.token);
  yield localStorage.setItem('expires_at', decoded.exp);
  yield localStorage.setItem('orig_iat', decoded.orig_iat);

  yield put(
    actions.notify({
      msg: 'Login successful, redirecting you to dashboard...',
      severity: 'success',
    }),
  );
}

export function* inviteVerifySuccessSaga(payload) {
  yield delay(100);
  const payloadToken = yield select(selectPayload);
  yield localStorage.setItem('token', payloadToken.token);
  yield localStorage.setItem('user', JSON.stringify(payloadToken.user));
  let decoded: any = jwt_decode(payloadToken.token);
  yield localStorage.setItem('expires_at', decoded.exp);
  yield localStorage.setItem('orig_iat', decoded.orig_iat);

}

export function* verifyInviteTokenSaga(action) {
  // yield takeLatest(actions.someAction.type, doSomething);
  yield delay(1000);
  const requestURL = host + `/auth/verify-invite/`;

  try {
    // Call our request helper (see 'utils/request')
    var options = {
      body: JSON.stringify(action.payload),
      method: 'POST',
      encoding: 'utf8',
      headers: {
        'User-Agent': 'request',
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };
    const response = yield call(request, requestURL, options);

    if (response) {
      yield put(actions.verifyInviteTokenSuccess(response));
    } else {
      yield put(actions.verifyInviteTokenError(AppErrorType.RESPONSE_ERROR));
    }
  } catch (err) {
    if (err.response?.status === 404) {
      yield put(actions.verifyInviteTokenError(AppErrorType.RESPONSE_ERROR));
    } else if (err.message === 'Failed to fetch') {
      yield put(actions.verifyInviteTokenError(AppErrorType.RESPONSE_ERROR));
    } else if (err.response?.status === 400) {
      let resp = null;
      resp = yield err.response.json();
      yield put(actions.verifyInviteTokenError(resp));
    } else {
      let resp = null;
      resp = yield err.response.json();
      yield put(actions.verifyInviteTokenError(resp));
    }
  }
}


export function* completeInviteOnboardSaga(action) {
  // yield takeLatest(actions.someAction.type, doSomething);
  yield delay(1000);
  const requestURL = host + `/user/complete-invite/`;

  try {
    // Call our request helper (see 'utils/request')
    var options = {
      body: JSON.stringify(action.payload),
      method: 'POST',
      encoding: 'utf8',
      headers: {
        'User-Agent': 'request',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        Accept: 'application/json',
      },
    };
    const response = yield call(request, requestURL, options);

    if (response) {
      yield put(actions.completeInviteOnboardSuccess(response));
    } else {
      yield put(actions.completeInviteOnboardError(AppErrorType.RESPONSE_ERROR));
    }
  } catch (err) {
    if (err.response?.status === 404) {
      yield put(actions.completeInviteOnboardError(AppErrorType.RESPONSE_ERROR));
    } else if (err.message === 'Failed to fetch') {
      yield put(actions.completeInviteOnboardError(AppErrorType.RESPONSE_ERROR));
    } else if (err.response?.status === 400) {
      let resp = null;
      resp = yield err.response.json();
      yield put(actions.completeInviteOnboardError(resp));
    } else {
      let resp = null;
      resp = yield err.response.json();
      yield put(actions.completeInviteOnboardError(resp));
    }
  }
}


export function* verifyResetTokenSaga(action) {
  // yield takeLatest(actions.someAction.type, doSomething);
  yield delay(1000);
  const requestURL = host + `/auth/verify/reset`;

  try {
    // Call our request helper (see 'utils/request')
    var options = {
      body: JSON.stringify(action.payload),
      method: 'POST',
      encoding: 'utf8',
      headers: {
        'User-Agent': 'request',
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };
    const response = yield call(request, requestURL, options);

    if (response) {
      yield put(actions.verifyResetTokenSuccess(response));
    } else {
      yield put(actions.verifyResetTokenError(AppErrorType.RESPONSE_ERROR));
    }
  } catch (err) {
    if (err.response?.status === 404) {
      yield put(actions.verifyResetTokenError(AppErrorType.RESPONSE_ERROR));
    } else if (err.message === 'Failed to fetch') {
      yield put(actions.verifyResetTokenError(AppErrorType.RESPONSE_ERROR));
    } else if (err.response?.status === 400) {
      let resp = null;
      resp = yield err.response.json();
      yield put(actions.verifyResetTokenError(resp));
    } else {
      let resp = null;
      resp = yield err.response.json();
      yield put(actions.verifyResetTokenError(resp));
    }
  }
}



export function* verifyAuthTokenSaga(action) {
  // yield takeLatest(actions.someAction.type, doSomething);
  yield delay(1000);
  const requestURL = host + `/auth/verify-token/`;

  try {
    // Call our request helper (see 'utils/request')
    var options = {
      body: JSON.stringify(action.payload),
      method: 'POST',
      encoding: 'utf8',
      headers: {
        'User-Agent': 'request',
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };
    const response = yield call(request, requestURL, options);

    if (response) {
      yield put(actions.verifyTokenSuccess(response));
    } else {
      yield put(actions.verifyTokenError(AppErrorType.RESPONSE_ERROR));
    }
  } catch (err) {
    if (err.response?.status === 404) {
      yield put(actions.verifyTokenError(AppErrorType.RESPONSE_ERROR));
    } else if (err.message === 'Failed to fetch') {
      yield put(actions.verifyTokenError(AppErrorType.RESPONSE_ERROR));
    } else if (err.response?.status === 400) {
      let resp = null;
      resp = yield err.response.json();
      yield put(actions.verifyTokenError(resp));
    } else {
      let resp = null;
      resp = yield err.response.json();
      yield put(actions.verifyTokenError(resp));
    }
  }
}

export function* verifyVerificationSaga(action) {
  yield delay(100);
  const requestURL = host + `/auth/verifyGoogle`;
  try {
    // Call our request helper (see 'utils/request')
    var options = {
      body: JSON.stringify(action.payload),
      method: 'POST',
      encoding: 'utf8',
      headers: {
        'User-Agent': 'request',
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };
    const response = yield call(request, requestURL, options);

    if (response) {
      yield put(actions.verifyVerificationSuccess(response));
    } else {
      yield put(
        actions.notify({
          msg: 'Invalid Authentication Code..',
          severity: 'error',
        }),
      );
    }
  } catch (err: any) {
    console.log('errrrr', err);
    yield put(
      actions.notify({
        msg: 'Invalid Authentication Code..',
        severity: 'error',
      }),
    );
  }
}

export function* loginFailedSaga(payload) {
  yield delay(100);
  yield put(
    actions.notify({
      msg: 'Login was unsucessful! Please try again or contact support for assistance.',
      severity: 'error',
    }),
  );
}

export function* getUserSaga() {
  yield delay(100);
  const token = yield localStorage.getItem('token');
  const user = yield localStorage.getItem('user');
  var payload = { token: token, user: user };
}

export function* logoutSaga() {
  yield delay(100);
  yield localStorage.removeItem('token');
  yield localStorage.removeItem('user');
  yield localStorage.removeItem('expires_at');
  yield localStorage.removeItem('orig_iat');
  yield window.location.reload();
  yield put(actions.logoutSuccess());
}

export function* forgotPWSaga(action) {
  yield delay(100);
  const requestURL = host + `/auth/reset/`;

  try {
    var options = {
      body: JSON.stringify(action.payload),
      method: 'POST',
      encoding: 'utf8',
      headers: {
        'User-Agent': 'request',
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };
    const response = yield call(request, requestURL, options);

    if (response) {
      yield put(actions.forgotPasswordLoaded(response));
    } else {
      yield put(actions.forgotPasswordError(AppErrorType.RESPONSE_ERROR));
    }
  } catch (err) {
    if (err.response?.status === 404) {
      yield put(actions.forgotPasswordError(AppErrorType.RESPONSE_ERROR));
    } else if (err.message === 'Failed to fetch') {
      yield put(actions.forgotPasswordError(AppErrorType.RESPONSE_ERROR));
    } else {
      yield put(actions.forgotPasswordError(AppErrorType.RESPONSE_ERROR));
    }
  }
}

export function* forgotPWSuccessSaga(payload) {
  yield delay(100);
  yield put(
    actions.notify({
      msg: 'Password reset link sent! Please check your email.',
      severity: 'success',
    }),
  );
}

export function* forgotPWFailureSaga(payload) {
  yield delay(100);
  yield put(
    actions.notify({
      msg: 'Could not send forgot password link! Please try again or contact support for assistance.',
      severity: 'error',
    }),
  );
}

export function* changePWSaga(action) {
  yield delay(100);
  const requestURL = host + `/auth/change-password/`;

  try {
    // Call our request helper (see 'utils/request')
    //const repos: Repo[] = yield call(request, requestURL);
    var options = {
      body: JSON.stringify(action.payload),
      method: 'POST',
      encoding: 'utf8',
      headers: {
        'User-Agent': 'request',
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };
    const response = yield call(request, requestURL, options);
    if (response) {
      yield put(actions.changePasswordSuccess(response));
    } else {
      yield put(actions.changePasswordError(AppErrorType.RESPONSE_ERROR));
    }
  } catch (err) {
    if (err.response?.status === 404) {
      yield put(actions.changePasswordError(AppErrorType.RESPONSE_ERROR));
    } else if (err.message === 'Failed to fetch') {
      yield put(actions.changePasswordError(AppErrorType.RESPONSE_ERROR));
    } else {
      yield put(actions.changePasswordError(AppErrorType.RESPONSE_ERROR));
    }
  }
}

export function* changePWSuccessSaga(payload) {
  yield delay(100);
  yield put(
    actions.notify({
      msg: 'Change password succesful! Please login in with your new credentials.',
      severity: 'success',
    }),
  );
}

export function* changePasswordErrorSaga(payload) {
  yield delay(100);
  yield put(
    actions.notify({
      msg: 'Could not cannot change password! Please try again or contact support for assistance.',
      severity: 'error',
    }),
  );
}

export function* confirmEmailSaga(action) {
  yield delay(100);
  const requestURL = host + `/auth/register/verify-email/`;

  try {
    var options = {
      body: JSON.stringify(action.payload),
      method: 'POST',
      encoding: 'utf8',
      headers: {
        'User-Agent': 'request',
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };
    const response = yield call(request, requestURL, options);

    //console.log(response)
    if (response) {
      yield put(actions.confirmEmailSuccess(response));
    } else {
      yield put(actions.confirmEmailError(AppErrorType.RESPONSE_ERROR));
    }
  } catch (err) {
    if (err.response?.status === 404) {
      yield put(actions.confirmEmailError(AppErrorType.RESPONSE_ERROR));
    } else if (err.message === 'Failed to fetch') {
      yield put(actions.confirmEmailError(AppErrorType.RESPONSE_ERROR));
    } else {
      yield put(actions.confirmEmailError(AppErrorType.RESPONSE_ERROR));
    }
  }
}

export function* confirmEmailSuccessSaga(payload) {
  yield delay(100);
}

export function* confirmEmailFailureSaga(payload) {
  yield delay(100);
}

export function* getNotifications() {
  yield delay(500);

  const requestURL = host + `/user/notifications/`;
  try {
    // Call our request helper (see 'utils/request')
    //const repos: Repo[] = yield call(request, requestURL);
    var options = {
      encoding: 'utf8',
      headers: {
        'User-Agent': 'request',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        Accept: 'application/json',
      },
    };
    const response = yield call(request, requestURL, options);

    if (response) {
      yield put(actions.notificationsLoaded(response));
    } else {
      yield put(actions.notificationsError(AppErrorType.RESPONSE_ERROR));
    }
  } catch (err) {
    if (err.response?.status === 404) {
      yield put(actions.notificationsError(AppErrorType.RESPONSE_ERROR));
    } else if (err.message === 'Failed to fetch') {
      yield put(actions.notificationsError(AppErrorType.RESPONSE_ERROR));
    } else {
      yield put(actions.notificationsError(AppErrorType.RESPONSE_ERROR));
    }
  }
}

export function* clearNotificationsSaga(action) {
  yield delay(500);
  const requestURL = host + `/user/notifications/clear`;

  try {
    var options = {
      method: 'POST',
      encoding: 'utf8',
      headers: {
        'User-Agent': 'request',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        Accept: 'application/json',
      },
    };
    const response = yield call(request, requestURL, options);

    if (response) {
      yield put(actions.clearNotificationsLoaded(response));
    } else {
      yield put(actions.clearNotificationsError(AppErrorType.RESPONSE_ERROR));
    }
  } catch (err) {
    if (err.response?.status === 404) {
      yield put(actions.clearNotificationsError(AppErrorType.RESPONSE_ERROR));
    } else if (err.message === 'Failed to fetch') {
      yield put(actions.clearNotificationsError(AppErrorType.RESPONSE_ERROR));
    } else {
      yield put(actions.clearNotificationsError(AppErrorType.RESPONSE_ERROR));
    }
  }
}

export function* appSaga() {
  // yield takeLatest(actions.someAction.type, doSomething);
  yield takeLatest(actions.signup.type, submitSignup);
  yield takeLatest(actions.signupSuccess.type, signupSuccessSaga);
  yield takeLatest(actions.signupError.type, signupErrorSaga);
  yield takeLatest(actions.login.type, submitLogin);
  yield takeLatest(actions.verifyToken.type, verifyAuthTokenSaga);
  yield takeLatest(actions.verifyTokenSuccess.type, loginSuccessSaga);


  yield takeLatest(actions.loginSuccess.type, loginSuccessSaga);
  yield takeLatest(actions.verifyVerification.type, verifyVerificationSaga);
  yield takeLatest(actions.verifyVerificationSuccess.type, loginSuccessSaga);

  yield takeLatest(actions.verifyInviteToken.type, verifyInviteTokenSaga);
  yield takeLatest(actions.completeInviteOnboard.type, completeInviteOnboardSaga);
  yield takeLatest(actions.verifyInviteTokenSuccess.type, inviteVerifySuccessSaga);


  yield takeLatest(actions.verifyResetToken.type, verifyResetTokenSaga);
  //yield takeLatest(actions.verifyResetTokenSuccess.type, loginSuccessSaga);
  //yield takeLatest(actions.verifyResetTokenError.type, verifyInviteTokenSaga);



  yield takeLatest(actions.loginError.type, loginFailedSaga);
  yield takeLatest(actions.logout.type, logoutSaga);
  yield takeLatest(actions.forgotPassword.type, forgotPWSaga);
  yield takeLatest(actions.forgotPasswordLoaded.type, forgotPWSuccessSaga);
  yield takeLatest(actions.forgotPasswordError.type, forgotPWFailureSaga);
  yield takeLatest(actions.changePassword.type, changePWSaga);
  yield takeLatest(actions.changePasswordSuccess.type, changePWSuccessSaga);
  yield takeLatest(actions.changePasswordError.type, changePasswordErrorSaga);
  yield takeLatest(actions.confirmEmail.type, confirmEmailSaga);
  yield takeLatest(actions.confirmEmailSuccess.type, confirmEmailSuccessSaga);
  yield takeLatest(actions.confirmEmailError.type, confirmEmailFailureSaga);
  yield takeLatest(actions.loadNotifications.type, getNotifications);
  yield takeLatest(actions.clearNotifications.type, clearNotificationsSaga);
}
