import * as React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { media, sizes } from '../../../styles/media';
import scss_variables from '../../../styles/_variables.scss';
import { FaHome, FaCog, FaUsers, FaProjectDiagram} from 'react-icons/fa';

interface Props{
  isActive?:boolean;
}

export function Sidebar({isActive}:Props) {
  return (
    <div className={isActive === true ? "sidebar": "sidebar inactive"}>
      <Link to="/">
        <div className="sidebar-link">
          <FaHome size="2.5rem" color="white"/>
        </div>
      </Link>
      <Link to="/projects">
        <div className="sidebar-link">
          <FaProjectDiagram size="2.5rem" color="white"/>
        </div>
      </Link>
      <Link to="/organization">
        <div className="sidebar-link">
          <FaUsers size="2.5rem" color="white"/>
        </div>
      </Link>
      <Link to="/account">
        <div className="sidebar-link">
          <FaCog size="2.5rem" color="white"/>
        </div>
      </Link>
    </div>
  );
}
