import * as React from 'react';
import styled from 'styled-components/macro';
import { media, sizes } from '../../../styles/media';
import scss_variables from '../../../styles/_variables.scss';
import {useMediaQuery} from '@react-hook/media-query'

interface Props{
}

export function Logo(props:Props) {

  const isMobile = useMediaQuery('(max-width: 430px)')

  return (
    <div className="logo">
      <a href="/">
        {isMobile
          ? <h4 className="white">Inclusion Score Solvr</h4>
          : <h2 className="white">Inclusion Score Solvr</h2>
        }

      </a>
    </div>
  );
}
