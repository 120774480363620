/**
 *
 * Layout
 *
 */
import * as React from 'react';
import styled from 'styled-components/macro';
import { useNavigate } from 'react-router-dom';
import { Nav } from '../Nav';
import { Sidebar } from '../Nav/Sidebar';
import {Grid } from '@mui/material';

interface Props {
  children: React.ReactNode;
  bg_image?:string;
}

export function Layout(props: Props) {
  const history = useNavigate();

  const [sidebarActive, setSideBarActive] = React.useState(true)

  function toggleSideBar() {
    setSideBarActive(!sidebarActive)
  }

  return (
    <div className="app-layout" style={{backgroundImage:props.bg_image ? `url('${props.bg_image}')` : 'inherit'}}>
      <Nav toggleSideBar={toggleSideBar}/>
      <Sidebar isActive={sidebarActive}/>
      <Grid container spacing={2} pl={sidebarActive ? 15 : 5} pt={15} pr={5} pb={5}>
        <Grid item xs={12}>
        {props.children}
        </Grid>
      </Grid>
    </div>
  );
}

export function LayoutNoSidebar(props: Props) {
  return (
    <div className="app-layout">
      <Nav/>
      <Grid container spacing={2} pl={5} pt={15} pr={5} pb={5}>
        <Grid item xs={12}>
        {props.children}
        </Grid>
      </Grid>
    </div>
  );
}
