import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { appSaga } from './saga';
import { AppState } from './types';
import jwt_decode from 'jwt-decode';

export const initialState: AppState = {
  loading: false,
  error: null,
  payload: null,
  user: null,
  token: null,
  is_authenticated: false,
  success: false,
  onboard_success:false,
  token_reset_sucess:false,
  changepw_sucess:false,
  notifications: [],
  appAlert: '',
  appAlertSeverity: 'success',
  appAlertOpen: false
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    signup(state, action: PayloadAction<any>) {
      const data = action.payload;
      state.loading = true;
      state.error = null;
      state.payload = data;
      state.token = null;
    },
    signupSuccess(state, action: PayloadAction<any>) {
      const data = action.payload;
      state.payload = data;
      state.loading = false;
      state.token = data.token;
      state.success = true;
    },
    signupError(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    },
    login(state, action: PayloadAction<any>) {
      const data = action.payload;
      state.loading = true;
      state.error = null;
      state.payload = data;
      state.token = null;
    },
    loginSuccess(state, action: PayloadAction<any>) {
      const data = action.payload;
      state.payload = data;
      state.loading = false;
      state.token = data.token;
      state.user = data.user;
      state.success = true;
      state.is_authenticated = true;
    },
    loginError(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    },
    verifyResetToken(state, action: PayloadAction<any>) {
      const data = action.payload;
      state.loading = true;
      state.payload = data;
    },
    verifyResetTokenSuccess(state, action: PayloadAction<any>) {
      const data = action.payload;
      state.loading = false;
      state.payload = data;
      state.token_reset_sucess = true;
      // state.token = data.token;
      // state.user = data.user;
      // state.is_authenticated = true;
      // localStorage.setItem('user', JSON.stringify(data.user));
    },
    verifyResetTokenError(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    },
    verifyToken(state, action: PayloadAction<any>) {
      const data = action.payload;
      state.loading = true;
      state.payload = data;
    },
    verifyTokenSuccess(state, action: PayloadAction<any>) {
      const data = action.payload;
      state.loading = false;
      state.payload = data;
      state.success = true;
      state.token = data.token;
      state.user = data.user;
      state.is_authenticated = true;
      localStorage.setItem('user', JSON.stringify(data.user));
    },
    verifyTokenError(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    },
    verifyVerification(state, action: PayloadAction<any>) {
      const data = action.payload;
      state.loading = true;
      state.payload = data;
    },
    verifyVerificationSuccess(state, action: PayloadAction<any>) {
      const data = action.payload;
      state.loading = false;
      state.payload = data;
      state.success = true;
      state.is_authenticated = true;
      localStorage.setItem('user', JSON.stringify(data.user));
    },
    verifyVerificationError(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    },
    verifyInviteToken(state, action: PayloadAction<any>) {
      const data = action.payload;
      state.loading = true;
      state.payload = data;
    },
    verifyInviteTokenSuccess(state, action: PayloadAction<any>) {
      const data = action.payload;
      state.loading = false;
      state.payload = data;
      state.success = true;

    },
    verifyInviteTokenError(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    },
    completeInviteOnboard(state, action: PayloadAction<any>) {
      const data = action.payload;
      state.loading = true;
      state.payload = data;
    },
    completeInviteOnboardSuccess(state, action: PayloadAction<any>) {
      const data = action.payload;
      state.loading = false;
      state.payload = data;
      state.onboard_success = true;

    },
    completeInviteOnboardError(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    },
    confirmEmail(state, action: PayloadAction<any>) {
      const data = action.payload;
      state.loading = true;
      state.error = null;
      state.payload = data;
    },
    confirmEmailSuccess(state, action: PayloadAction<any>) {
      const data = action.payload;
      state.payload = data;
      state.loading = false;
    },
    confirmEmailError(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    },
    forgotPassword(state, action: PayloadAction<any>) {
      const data = action.payload;
      state.loading = true;
      state.error = null;
      state.payload = data;
      state.token = null;
    },
    forgotPasswordLoaded(state, action: PayloadAction<any>) {
      const data = action.payload;
      state.payload = data;
      state.loading = false;
      state.token = data.token;
    },
    forgotPasswordError(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    },
    changePassword(state, action: PayloadAction<any>) {
      const data = action.payload;
      state.loading = true;
      state.error = null;
      state.payload = data;
      state.changepw_sucess = false;
    },
    changePasswordSuccess(state, action: PayloadAction<any>) {
      const data = action.payload;
      state.payload = data;
      state.loading = false;
      state.changepw_sucess = true;
    },
    changePasswordError(state, action: PayloadAction<any>) {
      const data = action.payload;
      state.loading = true;
      state.error = null;
      state.payload = data;
    },
    logout(state) {
      state.loading = true;
      state.error = null;
      state.token = null;
    },
    logoutSuccess(state) {
      state.loading = false;
    },
    logoutError(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    },
    loadNotifications(state) {
      state.loading = true;
      state.error = null;
      state.notifications = [];
    },
    notificationsLoaded(state, action: PayloadAction<any>) {
      const data = action.payload;
      state.notifications = data;
      state.loading = false;
    },
    notificationsError(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    },
    clearNotifications(state) {
      state.loading = true;
      state.error = null;
    },
    clearNotificationsLoaded(state, action: PayloadAction<any>) {
      const data = action.payload;
      state.notifications = data;
      state.loading = false;
    },
    clearNotificationsError(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    },
    notify(state, action: PayloadAction<any>) {
      const data = action.payload;
      state.appAlertSeverity = data.severity;
      state.appAlert = data.msg;
      state.appAlertOpen = true;
      state.loading = false;
    },
    closeNotify(state) {
      state.appAlertOpen = false;
    },
    set_error(state, action: PayloadAction<any>) {
      const data = action.payload;
      state.error = data;
    },
    set_authUser(state, action: PayloadAction<any>) {
      const data = action.payload;
      state.user = data;
    },
    set_authToken(state, action: PayloadAction<any>) {
      const data = action.payload;
      state.token = data;
      state.is_authenticated = true;
    }
  },
});

export const { actions: appActions } = slice;

export const useAppSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: appSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useAppSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
