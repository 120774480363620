import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Nav } from '../../components/Nav';
import { Logo } from '../../components/Nav/Logo';
import { Footer } from '../../components/Footer';
import { LayoutNoSidebar } from '../../components/Layout';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link, useLocation} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  selectLoading,
  selectPayload,
  selectError,
  selectUser,
  selectToken,
  selectSuccess,
} from '../../slice/selectors';
import {Grid, Box, Avatar, TextField, FormControl, FormLabel,  Typography, Button, CircularProgress} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useAppSlice } from '../../slice';
import { renderAPIMsg } from '../../../utils/request';



export function Integration() {
  const history = useNavigate();
  const { actions } = useAppSlice();

  const dispatch = useDispatch();

  const authUser = useSelector(selectUser);
  const loading = useSelector(selectLoading);
  const errors = useSelector(selectError);
  const success = useSelector(selectSuccess);
  const payload = useSelector(selectPayload);
  const { t, i18n } = useTranslation();

  const [loginWithOTP, setLoginWithOTP] = React.useState(false)
  const [email, setEmail] = React.useState('')
  const [otpEmail, setOtpEmail] = React.useState('')


  const [password, setPassword] = React.useState('')
  const [otp_secret, setOtp_secret] = React.useState('')
  const [otp_code, set_otp_code] = React.useState('')

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();

  const card_id = query.get('card_id')
  const token = query.get('token')


  React.useEffect(() => {
    dispatch(actions.verifyToken({token:token}))
  }, [token, card_id]);

  React.useEffect(() => {
    if (success !== undefined && success !==null && success === true) {
      history(`/?card_id=${card_id}`)
    }
  }, [success, card_id]);


  return (
    <>
      <Helmet>
        <title>Login</title>
        <meta name="description" content="Security compliance made easy" />
      </Helmet>
      <LayoutNoSidebar>
      <Grid container spacing={2} justifyContent={'center'}>
        <Grid item xs={12}>
          <Logo/>
        </Grid>
        <Grid item xs={12} sm={8} md={9} lg={6}>
          <div className="split-card-container">
            <div className="col-flex">
              <div className="col-left">
                <div className="card-header">
                  <h2>Connecting To Solvr...</h2>
                  {errors !== undefined && errors !== null
                  ? <p className="red">{renderAPIMsg(errors)}</p>
                  :null}
                </div>
                <div className="card-body">
                  {loading
                    ? <CircularProgress/>
                    : null
                  }
                </div>
              </div>
              <div className="col-right">
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                  <p style={{margin:0, paddingBottom:20}}>Join the thousands of different companies looking to streamline their security and DI policies.</p>
                </Box>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      </LayoutNoSidebar>
    </>
  );
}
