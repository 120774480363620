import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    height: 100vh;
  }

  #root {
    min-height: 100%;
    min-width: 100%;
  }

  a{
    cursor:pointer;
    color:#000;
    text-decoration:none;
    :hover{
        text-decoration:underline;
    }
  }

  label {
    font-family: 'Inter', serif;
    line-height: 1.5em;
  }

  input, select {
    font-family: inherit;
    font-size: inherit;
  }

`;
