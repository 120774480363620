import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.app || initialState;

export const selectApp = createSelector([selectSlice], state => state);

export const selectLoading = createSelector(
  [selectSlice],
  appState => appState.loading,
);

export const selectError = createSelector(
  [selectSlice],
  appState => appState.error,
);

export const selectPayload = createSelector(
  [selectSlice],
  appState => appState.payload,
);

export const selectToken = createSelector(
  [selectSlice],
  appState => appState.token,
);

export const selectSuccess = createSelector(
  [selectSlice],
  appState => appState.success,
);

export const selectNotifications = createSelector(
  [selectSlice],
  appState => appState.notifications,
);

export const selectUser = createSelector(
  [selectSlice],
  appState => appState.user,
);

export const selectAppAlert = createSelector(
  [selectSlice],
  appState => appState.appAlert,
);

export const selectAppAlertOpen = createSelector(
  [selectSlice],
  appState => appState.appAlertOpen,
);

export const selectAppAlertSeverity = createSelector(
  [selectSlice],
  appState => appState.appAlertSeverity,
);

export const selectOnboardSuccess = createSelector(
  [selectSlice],
  appState => appState.onboard_success,
);

export const selectTokenResetSuccess = createSelector(
  [selectSlice],
  appState => appState.token_reset_sucess,
);

export const selectChangePWSuccess = createSelector(
  [selectSlice],
  appState => appState.changepw_sucess,
);
