import * as React from 'react';
import styled from 'styled-components/macro';
import { media, sizes } from '../../../styles/media';
// import scss_variables from '../../../styles/_variables';
import {
  useNavigate,
} from 'react-router-dom';
import {Avatar, Box, Skeleton, IconButton, Menu, MenuItem, Divider,ListItemIcon, CircularProgress, Grid, Typography} from '@mui/material';
import { FaDoorOpen, FaRegBell} from 'react-icons/fa';
import moment from 'moment';

interface Props{
  notifications?:any[];
  handleClearNotif: (e:any)=> void;
  loading:boolean;
}


export const NotifcationLink: React.FC<{notification:any}> = ({notification}) => {
  const history = useNavigate();
  let actor = ''
  actor = notification.actor.first_name +' '+ notification.actor.last_name
  let verb = ''
  verb = notification.type.verb
  let date = notification.createdAt
  let url = (notification && notification.url) ? notification.url : null

  return (<div onClick={()=>history(url)} style={{cursor:"pointer"}}>
        <Grid container spacing={3} alignItems="center" p={1}>
          <Grid item xs={1}>
            {notification && notification.is_read === false
            ? <Box sx={{borderRadius:20, width:10,height:10, backgroundColor:"green"}}></Box>
            : null
            }
          </Grid>
          <Grid item xs={2}>
            <Avatar src={notification && notification.actor?.profile_image} sx={{width:60, height:60}}/>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1" alignSelf="flex-start" sx={{fontSize:'12px'}}>
              {actor} {verb}. <span style={{color:"grey"}}>{moment(date).format('MM/DD @ hh:mm a')}</span>
            </Typography>
          </Grid>
        </Grid>
      </div>
  );
}


export function NotificationDropdown(props:Props) {

   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
   const open = Boolean(anchorEl);
   const handleClick = (event: React.MouseEvent<HTMLElement>) => {
     setAnchorEl(event.currentTarget);
   };
   const handleClose = () => {
     setAnchorEl(null);
   };

  return (
    <div className="avatar-flex">
      {props.notifications
      ? (<>

        <IconButton
           onClick={handleClick}
           size="small"
           sx={{ ml: 2 }}
           aria-controls={open ? 'account-menu' : undefined}
           aria-haspopup="true"
           aria-expanded={open ? 'true' : undefined}
         >
          <FaRegBell size="2rem" color="white"/>

         </IconButton>
         {props.notifications && props.notifications.length > 0
         ? <Box sx={{borderRadius:20, position:"relative", width:10,height:10, backgroundColor:"green", left:"-15px", top:"-10px"}}></Box>
         : null
         }
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                width:220,
                borderRadius:3,
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 10,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >

            {props.notifications
              ? (
                <>
                {props.notifications.length > 0
                ? (
                  <>
                    {props.notifications.map(notification => {
                      return <NotifcationLink notification={notification}/>
                    })}
                  </>
                )
                :(<Box display="flex" justifyContent="center" alignItems={'center'} p={1} >
                  <p>No Notifications</p>
                  </Box>
                )
                }
                </>
              )
              : <CircularProgress/>
            }

            <Divider />
            <Box display="flex" justifyContent="center" alignItems={'center'}>
            {props.loading
            ? (
              <CircularProgress/>
            )
            : (<MenuItem sx={{width:"100%", textAlign:"center", paddingLeft:5}} onClick={props.handleClearNotif}>
                Clear Notifications
              </MenuItem>)
            }
            </Box>
          </Menu>
        </>
      )
      :<Skeleton />
      }
    </div>
  );
}
