import * as React from 'react';
import styled from 'styled-components/macro';
import { media, sizes } from '../../../styles/media';
// import scss_variables from '../../../styles/_variables';
import {Avatar, Skeleton, IconButton, Menu, MenuItem, Divider,ListItemIcon} from '@mui/material';
import { FaDoorOpen } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import {useMediaQuery} from '@react-hook/media-query'

interface Props{
  user?:any;
  handleLogout: (e:any)=> void;
}

export function AvatarDropdown(props:Props) {

   const history = useNavigate();
   const isMobile = useMediaQuery('(max-width: 430px)')


   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
   const open = Boolean(anchorEl);
   const handleClick = (event: React.MouseEvent<HTMLElement>) => {
     setAnchorEl(event.currentTarget);
   };
   const handleClose = () => {
     setAnchorEl(null);
   };

  return (
    <div className="avatar-flex">
      {props.user
      ? (<>

        {!isMobile &&
          <h3 className="avatar-name">{props.user.first_name || ''} {props.user.last_name || ''}</h3>
        }

        <IconButton
           onClick={handleClick}
           size="small"
           sx={{ ml: 2 }}
           aria-controls={open ? 'account-menu' : undefined}
           aria-haspopup="true"
           aria-expanded={open ? 'true' : undefined}
         >
           <Avatar src={props.user.profile_image} sx={{ width: 32, height: 32 }}/>
         </IconButton>

        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 10,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={()=>history('/account')}>
              My Account
            </MenuItem>
            <Divider />
            <MenuItem onClick={props.handleLogout}>
              <ListItemIcon>
                <FaDoorOpen size="1rem" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </>
      )
      :<Skeleton />
      }
    </div>
  );
}
